<template>
  <section class="honor">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <el-row :gutter="30">
        <el-col :span="24" :offset="0"><div class="title"><h1>{{ options.title }}</h1><p><strong>{{ options.tit }}</strong></p></div></el-col>
      </el-row>
      <el-row :gutter="30">
        <ArticleContent :data="options.content" class="honor-content" />
      </el-row>
    </section>
  </section>
</template>

<script>

import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/关于我们.png'),
        slogan: require('../../assets/images/2023/aboutus/slogan.png'),
      },
      options: {title: '', content: []}
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      let option
      if ( id ) {
        switch ( id ) {
          case '3':
            option = this.$store.getters.getHonor && JSON.parse(JSON.stringify(this.$store.getters.getHonor))
            this.setOptions(option)
            break
          default:
            option = this.$store.state.getHonor
            this.setOptions(option)
        }
      }
    },
    setOptions (obj) {
      this.$set(this.options, 'title', obj.title)
      this.$set(this.options, 'content', obj.content)
      if (obj.tit) this.$set(this.options, 'tit', obj.tit)
      if (obj.url) this.$set(this.options, 'url', obj.url)
      if (obj.rightTitle) this.$set(this.options, 'rightTitle', obj.rightTitle)
      if (obj.rightContent) this.$set(this.options, 'rightContent', obj.rightContent)
    },
  }
}
</script>

<style scoped>
.honor {background-color: #ecf0f5; padding-bottom: 1rem;}

.honor .title {font-weight: 600; border-bottom: 1px solid #666; padding: 1rem 0;}
.honor .title h1 {font-size: 3rem; color: rgba(82, 0, 0, .9);line-height:1.6; font-weight: 600;}
.honor .honor-content {margin-top: 3rem;}
</style>